.prevCont{
  position: absolute;
  bottom: 0;
  width: 0;
  box-sizing: border-box;
  z-index: 9999;
}

.prevScreen{
  position: absolute;
  bottom: 6px;
  margin-left: auto;
  width: 100vw;
  height: 100vh;
  display: none;
  transform-origin: bottom left;
  transition: all ease-in-out 200ms;
  pointer-events: none;

  &[data-show="true"]{
    display: block;
    // transform: scale(0.5);
    transform: scale(0.12, 0.12) translate(-44%, 0);
    animation: fadein 1s ease-in-out;
  }

  &:after{
    content: "";
    position: absolute;
    top: -80px;
    left: -60px;
    width: calc(100% + 120px);
    height: calc(100% + 160px);
    background: rgba(224, 224, 224, 0.8);
    z-index: 0;
  }

  .dpShad{
    filter: none;
  }
}

.floatTab {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // -webkit-backdrop-filter: blur(5px);
  // backdrop-filter: blur(5px);
  overflow: hidden;
  border-radius: 6px;
  transition: all ease-in-out 200ms;
  transform-origin: center;
  display: flex;
  flex-direction: column;

  &[data-size="full"] {
    border-radius: 0;
  }

  &[data-size="cstm"] {
    border-radius: 0;
    filter: none;
    border: solid 1px #c7c7c7;
  }

  &[data-hide="true"] {
    top: 0;
    left: 0;
    transform: scale(0.8);
    opacity: 0;
    pointer-events: none;
  }

  &[data-max="false"] {
    top: calc(100% + 10px);
    transform: scale(0);
    transform-origin: bottom;
    opacity: 0;
    pointer-events: none;
  }

  &[data-size="mini"] {
    top: 10%;
    left: 20%;
    width: 60%;
    height: 80%;
  }
}

.absolute{
  position: absolute;
}

.appFullName[data-white="true"] {
  color: #fefefe;
}

.windowScreen {
  width: 100%;
  flex-grow: 1;

  &[data-dock="true"]{
    // height: calc(100% - 26px);
  }
}

.overTool {
  position: absolute;
  top: 0;
  width: auto;
  height: 26px;

  .btab {
    width: 176px;
    height: 100%;
    margin: 0 4px;
    border-radius: 4px 4px 0 0;
    box-shadow: 2px 0 2px rgba(80, 80, 80, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    font-size: 0.72em;

    .uicon {
      color: #505050;
    }
  }
}

.restWindow {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  flex-grow: 1;
  scroll-behavior: smooth;
}

.addCont {
  width: 80%;

  input {
    box-sizing: border-box;
  }
}

.cmdcont{
  height: calc(100% - 24px);
}

.cmdLine {
  font-size: 0.84em;
  color: rgba(255, 255, 255, 0.72);
  font-family: "consolas", monospace;
  height: 1.2em;
  font-weight: 400;
  overflow: hidden;
  max-width: 100%;
}

.actmd {
  height: auto;
  display: flex;
}

.ipcmd {
  background: inherit;
  color: inherit;
  font-family: inherit;
  padding-right: 100px;
  // width: calc(100% - 120px);
  // max-width: calc();
  position: relative;
  caret-color: transparent;
  margin: 0;

  &::after{
    content: "";
    position: absolute;
    bottom: 0;
    width: 8px;
    height: 2px;
    background: #ccc;
    animation: blink 1s step-end infinite;
  }

  &:active,
  &:focus {
    outline: none;
    border: none;
    caret-color: #ccc;

    &::after{
      display: none;
    }
  }
}

@keyframes blink {
  from,
  to {
    background: transparent;
  }
  50% {
    background: #ccc;
  }
}

.noteText{
  background: inherit;
  color: #111;
  font-family: inherit;
  font-size: 0.88em;
  resize: none;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  outline: none;
  border: none;
  padding: 4px 8px;

  &::selection{
    background: #0074ff;
    color: #fefefe;
  }

  &:active,
  &:focus {
    outline: none;
    border: none;
  }
}
